var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('section',{staticClass:"bg-white"},[_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"card border-dark"},[_vm._m(0),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped log-table-new mb-0"},[_vm._m(1),_c('tbody',_vm._l((_vm.nftMarketplace.results),function(market,index){return _c('tr',{key:index,staticClass:"align-middle"},[_c('td',{staticClass:"text-center ps-2"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_c('div',{staticClass:"d-flex justify-content-start align-items-center gap-3"},[_c('img',{staticClass:"img-responsive rounded-10",attrs:{"src":market.logo,"width":"40px","height":"40px","alt":""}}),_c('router-link',{staticClass:"fw-bold text-decoration-none text-dark",attrs:{"to":'/nft-marketplace/' +
                          market.networkKey +
                          '/' +
                          market.dappKey}},[_vm._v(_vm._s(market.name))])],1)]),_c('td',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(_vm._f("toUSD")(market.avgPriceInFiat)))]),(market.avgPriceInFiatChange > 0)?_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(Number(market.avgPriceInFiatChange).toFixed(2))+"% ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(Number(market.avgPriceInFiatChange).toFixed(2))+"% ")])])]),_c('td',{staticClass:"text-start"},[_c('div',[_c('div',[_vm._v(" "+_vm._s(Number(market.tradersCount).toLocaleString())+" ")]),(market.tradersCountChange > 0)?_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(Number(market.tradersCountChange).toFixed(2))+"% ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(market.tradersCountChange)+"% ")])])]),_c('td',{staticClass:"text-start"},[_c('div',[_c('div',[_vm._v("$"+_vm._s(_vm._f("formatNumber")(market.volumeInFiat)))]),(market.volumeInFiatChange > 0)?_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(Number(market.volumeInFiatChange).toFixed(2))+"% ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(Number(market.volumeInFiatChange).toFixed(2))+"% ")])])])])}),0)])]),_c('div',{staticClass:"row mt-3"},[_c('ul',{staticClass:"pagination pagination-md justify-content-center text-center"},[_c('li',{staticClass:"page-item",class:_vm.page === 1 ? 'disabled' : ''},[_c('a',{class:_vm.page === 1
                      ? 'page-link bg-outline-success border-success text-success'
                      : 'page-link bg-success border-success text-white',on:{"click":_vm.prevPage}},[_vm._v(" Previous ")])]),_c('li',{staticClass:"page-link text-success border-success border-start-0 border-end-0",staticStyle:{"background-color":"inherit"}},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.lastPage)+" ")]),_c('li',{staticClass:"page-item",class:_vm.page === _vm.lastPage ? 'disabled' : ''},[_c('a',{class:_vm.page === _vm.lastPage
                      ? 'page-link bg-outline-success border-success text-success'
                      : 'page-link bg-success border-success text-white',on:{"click":_vm.nextPage}},[_vm._v(" Next ")])])])])])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-dark text-white"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',{staticClass:"mb-0 py-2"},[_vm._v("Marketplaces Nft")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',{staticClass:"fw-bold text-center"},[_vm._v("#")]),_c('td',{staticClass:"fw-bold"},[_vm._v("Market")]),_c('td',{staticClass:"fw-bold"},[_vm._v("Average Price")]),_c('td',{staticClass:"fw-bold text-start"},[_vm._v("Traders")]),_c('td',{staticClass:"fw-bold text-start"},[_vm._v("Volume")])])])
}]

export { render, staticRenderFns }