<template>
  <div>
    <Header />
    <section class="bg-white">
      <div class="container py-4">
        <div class="card border-dark">
          <div class="card-header bg-dark text-white">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 py-2">Marketplaces Nft</h6>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped log-table-new mb-0">
                <thead>
                  <tr>
                    <td class="fw-bold text-center">#</td>
                    <td class="fw-bold">Market</td>
                    <td class="fw-bold">Average Price</td>
                    <td class="fw-bold text-start">Traders</td>
                    <td class="fw-bold text-start">Volume</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(market, index) in nftMarketplace.results"
                    :key="index"
                    class="align-middle"
                  >
                    <td class="text-center ps-2">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="market.logo"
                          class="img-responsive rounded-10"
                          width="40px"
                          height="40px"
                          alt=""
                        /><router-link
                          :to="
                            '/nft-marketplace/' +
                            market.networkKey +
                            '/' +
                            market.dappKey
                          "
                          class="fw-bold text-decoration-none text-dark"
                          >{{ market.name }}</router-link
                        >
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <div>{{ market.avgPriceInFiat | toUSD }}</div>
                        <div
                          v-if="market.avgPriceInFiatChange > 0"
                          class="text-success"
                        >
                          {{ Number(market.avgPriceInFiatChange).toFixed(2) }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ Number(market.avgPriceInFiatChange).toFixed(2) }}%
                        </div>
                      </div>
                    </td>
                    <td class="text-start">
                      <div>
                        <div>
                          {{ Number(market.tradersCount).toLocaleString() }}
                        </div>
                        <div
                          v-if="market.tradersCountChange > 0"
                          class="text-success"
                        >
                          {{ Number(market.tradersCountChange).toFixed(2) }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ market.tradersCountChange }}%
                        </div>
                      </div>
                    </td>
                    <td class="text-start">
                      <div>
                        <div>${{ market.volumeInFiat | formatNumber }}</div>
                        <div
                          v-if="market.volumeInFiatChange > 0"
                          class="text-success"
                        >
                          {{ Number(market.volumeInFiatChange).toFixed(2) }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ Number(market.volumeInFiatChange).toFixed(2) }}%
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row mt-3">
              <ul
                class="
                  pagination pagination-md
                  justify-content-center
                  text-center
                "
              >
                <li class="page-item" :class="page === 1 ? 'disabled' : ''">
                  <a
                    :class="
                      page === 1
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="prevPage"
                  >
                    Previous
                  </a>
                </li>
                <li
                  class="
                    page-link
                    text-success
                    border-success border-start-0 border-end-0
                  "
                  style="background-color: inherit"
                >
                  {{ page }} of {{ lastPage }}
                </li>
                <li
                  class="page-item"
                  :class="page === lastPage ? 'disabled' : ''"
                >
                  <a
                    :class="
                      page === lastPage
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="nextPage"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "",
  components: { Header, Footer },
  data() {
    return {
      nftMarketplace: [],
      nftPage: 1,
      page: 1,
      perPage: 25,
    };
  },
  mounted() {
    this.getNftMarketplace();
  },
  created() {
    this.$mixpanel.track("Visit NFT Marketplaces List ");
  },
  computed: {
    showRepos() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      return this.nftMarketplace.results.slice(start, end);
    },
    lastPage() {
      let length = this.nftMarketplace.pageCount;
      return length;
    },
  },
  methods: {
    getNftMarketplace() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/marketplace/day?limit=25&page=" +
            this.page +
            "&currency=USD&sort=volumeInFiat&order=desc"
        )
        .then((res) => {
          this.nftMarketplace = res.data;
        });
    },
    prevPage() {
      this.page--;
      this.getNftMarketplace();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    nextPage() {
      this.page++;
      if (this.page == this.lastPage - 1) {
        this.nftPage++;
        this.getNftMarketplace();
      }
      this.getNftMarketplace();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
